<template>
  <v-row>
    <v-col>
      <v-row no-gutters class="align-center">
        Verified Members List
        <v-btn
          small
          outlined
          rounded
          color="blue-grey"
          class="ml-2"
          @click="fetchMembers"
        >
          <span>refresh</span>
          <v-icon right>mdi-reload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field label="Search" v-model="table.search">
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Cari</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-row>
      <br />
      <v-data-table
        :headers="table.headers"
        :items="allMembers"
        @click:row="editData"
        :loading="allMembers.length == 0"
        :search="table.search"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>

        <template v-slot:item.is_active="{ item }">
          <v-icon :color="item.is_active == 'yes' ? 'success' : 'error'">
            {{
              item.is_active == "yes" ? "mdi-check-circle" : "mdi-close-circle"
            }}
          </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog
      v-model="edit.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Edit Data Member </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="7">
              <v-card outlined :loading="edit.loading" :disabled="edit.loading">
                <v-card-text>
                  <v-form ref="editMember" v-if="edit.data">
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          label="Country"
                          :items="countries.list"
                          :loading="countries.loading"
                          :disabled="countries.loading"
                          item-text="name"
                          item-value="iso2"
                          @change="getStates"
                          v-model="countries.selected"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          label="State/Province"
                          :items="states.list"
                          item-text="name"
                          item-value="iso2"
                          @change="getCities"
                          :loading="states.loading"
                          :disabled="!countries.selected || states.loading"
                          v-model="states.selected"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          label="District/City"
                          :items="cities.list"
                          :disabled="!states.selected || states.loading"
                          item-text="name"
                          item-value="name"
                          v-model="cities.selected"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Name"
                          v-model="edit.data.name"
                        ></v-text-field>
                        <v-autocomplete
                          label="Status"
                          :items="edit.status"
                          item-text="text"
                          item-value="value"
                          v-model="edit.data.status"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Website ID"
                          v-model="edit.data.website_id"
                        ></v-text-field>
                        <v-text-field
                          label="Upline Website ID"
                          v-model="edit.data.upline"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Phone"
                          v-model="edit.data.phone"
                        ></v-text-field>
                        <v-autocomplete
                          label="Active"
                          :items="edit.active"
                          item-text="text"
                          item-value="value"
                          v-model="edit.data.is_active"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-textarea
                          label="Address"
                          v-model="edit.data.address"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-file-input
                      label="Profile Image"
                      messages="This feature is under development"
                      disabled
                    >
                      <template v-slot:append-outer>
                        <v-btn color="primary" text>Upload</v-btn>
                      </template>
                    </v-file-input>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn large depressed color="indigo accent-2" @click="saveEdit" :loading="edit.loading">
                    <span class="white--text">save</span>
                    <v-icon right color="white">mdi-content-save</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="5">
              <v-card
                outlined
                :loading="edit.links.loading || edit.stocks.loading"
                :disabled="edit.links.loading || edit.stocks.loading"
              >
                <v-tabs color="indigo accent-1">
                  <v-tab> Member Links </v-tab>
                  <v-tab> Member Stocks </v-tab>

                  <v-tab-item>
                    <v-card-text>
                      <v-row v-if="edit.links.data">
                        <v-col>
                          Member Links
                          <v-data-table
                            :headers="edit.links.table.headers"
                            :items="edit.links.data"
                          >
                            <template v-slot:item.updated_at="{ item }">
                              {{ item.updated_at | formatDate }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card-text>
                      <v-row v-if="edit.stocks.data">
                        <v-col>
                          Member Stocks
                          <v-data-table
                            :headers="edit.stocks.table.headers"
                            :items="edit.stocks.data"
                          >
                            <template v-slot:item.updated_at="{item}">
                              {{ item.updated_at | formatDate }}
                            </template>

                            <template v-slot:item.quantity="{item}">
                              {{ item.quantity }} pcs
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-tab-item>
                </v-tabs>
              </v-card>
              <br>
              <v-row no-gutters class="align-center">
                <v-btn block color="error" @click="deleteConfirmation">
                  <span>Delete this member</span>
                  <v-icon right>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="600" persistent>
      <v-alert class="mb-0" prominent icon="mdi-alert" color="warning" dark>
        <v-row no-gutters class="align-center">
          Yakin ingin menghapus data ini?
          <v-spacer></v-spacer>
          <v-btn text large @click="handleDelete">
            <span>ya</span>
          </v-btn>
          <v-btn depressed large class="ml-2" @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      config: {
        withCredentials: false,
        headers: {
          "X-CSCAPI-KEY": process.env.VUE_APP_COUNTRY_STATE_API_KEY,
        },
      },
      apps: [
        "Tokopedia",
        "Bukalapak",
        "Shopee",
        "Lazada",
        "Facebook",
        "Instagram",
        "TikTok",
        "WhatsApp",
      ],
      countries: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      states: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      cities: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      table: {
        loading: false,
        search: null,
        headers: [
          { text: "Website ID", value: "website_id" },
          { text: "Name", value: "name" },
          { text: "Phone", value: "phone" },
          { text: "Address", value: "address" },
          { text: "Country", value: "country" },
          { text: "State", value: "state" },
          { text: "District", value: "district" },
          { text: "Status", value: "status" },
          { text: "Upline", value: "upline" },
          {
            text: "Active",
            value: "is_active",
            align: "center",
            sortable: false,
          },
          // { text: "Profile Image", value: "profile_image", sortable: false },
          // { text: "Link", value: "link", sortable: false },
          { text: "Created At", value: "created_at" },
        ],
      },
      edit: {
        loading: false,
        dialog: false,
        status: [
          { text: "Agen", value: "agen" },
          { text: "Distributor", value: "distributor" },
          { text: "Reseller", value: "reseller" },
        ],
        active: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ],
        data: null,
        links: {
          loading: false,
          data: null,
          table: {
            headers: [
              { text: "App Name", value: "app_name" },
              { text: "URL", value: "url" },
              { text: "Updated At", value: "updated_at" },
            ],
          },
        },
        stocks: {
          loading: false,
          data: null,
          table: {
            headers: [
              { text: "Product Name", value: "product_name" },
              { text: "Quantity", value: "quantity" },
              { text: "Updated At", value: "updated_at" },
            ],
          },
        },
      },
      del: {
        loading: false,
        dialog: false,
        id: null,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateMember", "deleteMember", "fetchMembers"]),
    async getCountries() {
      this.edit.loading = true;
      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
      })
      .then(response => {
        // console.log(response.data)
        this.countries.list = response.data
        this.edit.loading = false
      })
      .catch(err => {
        console.log(err)
        this.edit.loading = false
      })
      /*await axios
        .get("https://api.countrystatecity.in/v1/countries", this.config)
        .then((response) => {
          this.countries.list = response.data;
            console.log(response.data);
          this.edit.loading = false;
        })
        .catch((err) => {
          this.edit.loading = false;
          console.log(err);
        });*/
    },
    async getStates() {
      this.countries.loading = true;
      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.states.list = response.data
        this.countries.loading = false
        this.getDetails("country", this.countries.selected);
        // console.log(response.data)
      })
      .catch(err => {
        this.countries.loading = false
        console.log(err)
      })
      /*await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states`,
          this.config
        )
        .then((response) => {
          this.countries.loading = false;
          this.states.list = response.data;
          //   console.log(response.data);
          this.getDetails("country", this.countries.selected);
        })
        .catch((err) => {
          this.countries.loading = false;
          console.log(err);
        });*/
    },
    async getCities() {
      this.states.loading = true;
      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states/${this.states.selected}/cities`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
      })
      .then(response => {
        this.cities.list = response.data
        this.getDetails("state", this.states.selected)
        this.states.loading = false
        // console.log(response.data)
      })
      .catch(err => {
        this.states.loading = false
        console.log(err)
      })
      /*await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states/${this.states.selected}/cities`,
          this.config
        )
        .then((response) => {
          this.states.loading = false;
          this.cities.list = response.data;
          //   console.log(response.data);
          this.getDetails("state", this.states.selected);
        })
        .catch((err) => {
          this.states.loading = false;
          console.log(err);
        }); */
    },
    async getDetails(type, code) {
      switch (type) {
        case "country": {
          await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${code}`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          })
          .then(response => {
            this.countries.detail = response.data
            this.setPhoneCode(response.data.phone_code)
          })
          .catch(err => {
            console.log(err)
          })

          /*await axios
            .get(
              `https://api.countrystatecity.in/v1/countries/${code}`,
              this.config
            )
            .then((response) => {
              this.countries.detail = response.data;
              this.setPhoneCode(response.data.phonecode);
              //   console.log(response.data);
            })
            .catch((err) => {
              console.log(err);
            });*/
          break;
        }
        case "state": {
          await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states/${code}`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          })
          .then(response => {
            this.states.detail = response.data
          })
          .catch(err => {
            console.log(err)
          })
          /*await axios
            .get(
              `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states/${code}`,
              this.config
            )
            .then((response) => {
              this.states.detail = response.data;
              //   console.log(response.data);
            })
            .catch((err) => {
              console.log(err);
            }); */
          break;
        }
      }
    },
    setPhoneCode(code) {
      code = code.toString().replace(/\D/g, "");
      this.form.data.phone = code;
      console.log(code);
    },
    async getLinks(member_id) {
      this.edit.links.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/${member_id}/links`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // console.log(response.data)
          this.edit.links.data = response.data;
          this.edit.links.loading = false
        })
        .catch((err) => {
          this.edit.links.loading = false
          console.log(err);
        });
    },
    async getStocks(member_id) {
      this.edit.stocks.loading = true;
      await axios.get(`${process.env.VUE_APP_API_URL}/api/member/${member_id}/stocks`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }
      })
      .then(response => {
        this.edit.stocks.data = response.data
        this.edit.stocks.loading = false
      })
      .catch(err => {
        this.edit.stocks.loading = false
        console.log(err)
      })
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.text = "Successfully Updated";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.text = "Error Occured";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
    editData(item) {
      // console.log(item);
      this.countries.selected = item.country;
      this.states.selected = item.state;
      this.cities.selected = item.district;
      this.getStates();
      this.getCities();
      this.getLinks(item.id)
      this.getStocks(item.id)
      this.edit.data = item;
      this.edit.dialog = true;
    },
    saveEdit(){
      let data = this.edit.data
      data['country'] = this.countries.selected
      data['state'] = this.states.selected
      data['district'] = this.cities.selected
      this.edit.loading = true

      this.updateMember(data).then(() => {
        this.edit.loading = false
        this.showNotification("success")
      }).catch(err => {
        console.log(err)
        this.edit.loading = false
        this.showNotification("error")
      })
    },
    deleteConfirmation() {
      this.del.dialog = true;
    },
    handleDelete(){
      this.del.loading = true;
      let data = this.edit.data

      this.deleteMember(data).then(() => {
        this.del.loading = false;
        this.del.dialog = false;
        this.edit.dialog = false
        this.showNotification("success");
      }).catch(err => {
        console.log(err)
        this.del.loading = false
        this.showNotification("error")
      })
    }
  },
  computed: {
    ...mapGetters(["allMembers"]),
  },
  filters: {
    formatDate: (value) => {
      return moment(value).format("Do MMM YYYY");
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>